<template>
  <section class="px-6 py-20 lg:px-8 bg-brand-air">
    <h2
      class="text-[24px] md:text-[32px] uppercase font-extrabold leading-tight opacity-0 translate-y-8 mb-4 sm:mx-auto sm:text-center"
      data-test="testimonials.wordForItTitle"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      Don't take just our word for it
    </h2>
    <p
      class="text-neutral-600 opacity-0 translate-y-8 max-w-[450px] sm:mx-auto sm:text-center mb-8"
      data-test="testimonials.wordForItText"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      We've insured and protected thousands of happy customers across the
      nation.
    </p>
    <div class="relative">
      <swiper
        :modules="[Autoplay, Navigation]"
        :slides-per-view="1"
        :space-between="50"
        :autoplay="{
          delay: 35000,
          disableOnInteraction: false,
        }"
        :loop="true"
        :navigation="{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }"
        :breakpoints="{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }"
      >
        <swiper-slide
          v-for="testimonial in testimonials"
          class="mx-auto max-w-2xl border border-brand-teal/50 p-12 rounded-2xl !h-auto opacity-0 scale-90"
          data-test="testimonials.slide"
          v-intersection-observer="
            ([{ isIntersecting, target }]) => {
              if (isIntersecting) {
                gsap.to(target, {
                  duration: 1.0,
                  opacity: 1,
                  scale: 1,
                  ease: 'expo',
                  delay: 0.2,
                })
              }
            }
          "
        >
          <figure>
            <p class="sr-only" data-test="testimonials.rating">
              5 out of 5 stars
            </p>
            <div class="flex gap-x-1 text-brand-teal">
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
            </div>
            <blockquote
              class="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9"
              data-test="testimonials.quote"
            >
              <p>"{{ testimonial.quote }}"</p>
            </blockquote>
            <figcaption class="mt-10 flex items-center gap-x-6">
              <div class="text-sm leading-6">
                <a
                  :href="testimonial.link"
                  class="font-semibold text-gray-900 underline"
                  data-test="testimonials.name"
                  target="_blank"
                >
                  {{ testimonial.name }}
                </a>
              </div>
            </figcaption>
          </figure>
        </swiper-slide>
      </swiper>

      <!-- Navigation Arrows -->
      <div
        class="swiper-button-prev !text-brand-teal !left-0 !h-10 !w-10 rounded-full bg-transparent border border-brand-teal/50 flex items-center justify-center after:!text-xl !top-[107%] hover:bg-brand-teal/25"
        data-test="testimonials.prevButton"
      ></div>
      <div
        class="swiper-button-next !text-brand-teal !right-0 !h-10 !w-10 rounded-full bg-transparent border border-brand-teal/50 flex items-center justify-center after:!text-xl !top-[107%] hover:bg-brand-teal/25"
        data-test="testimonials.nextButton"
      ></div>
    </div>

    <div
      class="text-center mt-12 opacity-0 translate-y-8"
      v-intersection-observer="
        ([{ isIntersecting, target }]) => {
          if (isIntersecting) {
            gsap.to(target, {
              duration: 1.0,
              opacity: 1,
              y: 0,
              ease: 'expo',
              delay: 0.2,
            })
          }
        }
      "
    >
      <a
        :href="linkUrl"
        class="inline-block bg-black text-white no-underline uppercase font-wide tracking-[1px] px-9 !py-3.5 text-center font-bold text-sm rounded-full transition hover:bg-black/75"
        data-test="testimonials.getQuoteButton"
        >Get Quote</a
      >
    </div>
  </section>
</template>
<script setup>
import gsap from 'gsap'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { vIntersectionObserver } from '@vueuse/components'
import { StarIcon } from '@heroicons/vue/20/solid'
import { defineProps } from 'vue'
import { useUTMLink } from '@/composables/useUTMLink'

import 'swiper/css'
import 'swiper/css/navigation'

const props = defineProps(['baseUrl', 'data'])
const { linkUrl } = useUTMLink(props.data?.default_get_quote_link?.url)

const testimonials = [
  {
    quote:
      'By far the most comprehensive flood insurance policy attainable at a very reasonable competitive premium.',
    name: 'Jordan F.',
    link: 'https://g.co/kgs/tEiF1Fm',
  },
  {
    quote: `Neptune's approach to flood insurance is a new approach above options we used to have. The technology is superior to others and actually look at each individual property's risk factors. Systems are great, underwriting and customer service have been terrific and both provided outstanding service.`,
    name: 'Kelly C.',
    link: 'https://g.co/kgs/bBzQxDy',
  },
  {
    quote: 'Flood insurance at a reasonable price.',
    name: 'Michael P.',
    link: 'https://g.co/kgs/ydPXtvQ',
  },
  {
    quote: 'Easy, quick, comprehensive process.',
    name: 'Neil K.',
    link: 'https://trstp.lt/ZuHQh9k1p1',
  },
  {
    quote: 'Very easy and clear to use.',
    name: 'CB',
    link: 'https://trstp.lt/ycAUZu3ynm',
  },

  {
    quote: `Easiest process of buying insurance I've ever had.`,
    name: 'Taylor',
    link: 'https://trstp.lt/3EuPQk3lVa',
  },
  {
    quote:
      'Simple and straightforward process. Lots of good information on the difference between getting coverage through a private insurer or FEMA. 5 stars - would recommend.',
    name: 'Mark R',
    link: 'https://www.trustpilot.com/reviews/65c69d6665fe8b3d83c67b85',
  },
  {
    quote:
      'Very helpful and knowledgeable. Answered all my questions and explained in depth. Very easy to get my policy.',
    name: 'Therese O',
    link: 'https://www.trustpilot.com/reviews/65a32a156e964981243a19d7',
  },

  {
    quote:
      'Easy to operate the website for quote details. Quick response to my email questions helped me get my coverage "just right".',
    name: 'Matt C',
    link: 'https://www.trustpilot.com/reviews/656f8d2d1bce7259cbcdc411',
  },

  {
    quote:
      'Was able to get a great price on my flood insurance through their online system and then their customer service reached out to let me know they were available with any questions or concerns. They made it very easy.',
    name: 'Linda',
    link: 'https://www.trustpilot.com/reviews/6536ad1ea7259bb6e922aca1',
  },
  {
    quote:
      'This is my 3rd year with Neptune Flood, they have always been resonably affordable. The staff has been very helpful and always resolved any questions I had. I would recommend them highly.',
    name: 'Jerry R',
    link: 'https://www.trustpilot.com/reviews/650ca08f247bfbbbc30120e0',
  },
]
</script>
